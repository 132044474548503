import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, Box, Text } from 'grommet';

const FieldInputWithErrors = (props) => {
  const {
    field,
    form,
    placeholder,
    inputComponent,
    floatingLabel,
    label,
    prompt,
    style,
    ...rest
  } = props;
  const { errors, touched, setFieldValue, isSubmitting } = form;
  const Component = inputComponent || TextInput;
  return (
    <Box
      direction={floatingLabel ? 'row' : 'column'}
      align="stretch"
      flex={true}
    >
      {floatingLabel && (
        <Box background="white" flex="grow" justify="center">
          <Text size="small" margin="small" wordBreak="keep-all" color="dark-3">
            {floatingLabel}
          </Text>
        </Box>
      )}
      {label && <Text weight={400}>{label}</Text>}
      <Component
        name={field.name}
        size="medium"
        placeholder={placeholder}
        field={field}
        form={form}
        onChange={(e) => {
          setFieldValue(field.name, e.value || e.target.value);
        }}
        onBlur={field.onBlur}
        value={field.value}
        prompt={prompt}
        autocapitalize={rest.autocapitalize ? 'words' : 'none'}
        style={{
          ...style,
          textTransform: rest.autocapitalize ? 'capitalize' : 'normal',
        }}
        {...rest}
      />
      {errors[field.name] && touched[field.name] && !isSubmitting && (
        <Text color="status-critical">{errors[field.name]}</Text>
      )}
    </Box>
  );
};

FieldInputWithErrors.propTypes = {
  placeholder: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  inputComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  floatingLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  style: PropTypes.object,
};

export default FieldInputWithErrors;
