import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { map, keys } from '../../lib/nodash';
import { Box, Button } from 'grommet';
import { Formik, Form, Field } from 'formik';
import InputFilesUpload from '../Inputs/InputFilesUpload';
import DynamicIcon from '../Icons/DynamicIcon';
import styled from 'styled-components';
import useAddToCart from '../Cart/useAddToCart';
import useCart from '../Cart/useCart';
import FieldInputWithErrors from '../Inputs/FieldInputWithErrors';
import InputTextArea from '../Inputs/InputTextArea';

const Actions = styled(Box)`
  padding: 10px 0 20px 0;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding: 30px 0 20px 0;
  }
`;

const FieldsContainer = styled(Box)`
  padding: 12px 0 0 0;
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding: 0 20px;
  }
`;

const ColorConsultation = ({ variantId, blok }) => {
  const submitRef = useRef(null);
  const { addToCart, loading } = useAddToCart();

  return (
    <Box fill>
      <Formik
        initialValues={{
          '_About the Space': '',
          '_Color Preferences': '',
          '_Other Info': '',
          _Links: '',
          _uploads: [],
        }}
        validate={(values) => {
          const errors = {};
          if (!values['_About the space']) {
            errors['_About the space'] = 'Required';
          }
          if (!values['_Color Preferences']) {
            errors['_Color Preferences'] = 'Required';
          }
          if (!values['_Other Info']) {
            errors['_Other Info'] = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const transformAttribute = (x) => ({
            key: x,
            value: Array.isArray(values[x]) ? values[x].join(', ') : values[x],
          });
          await addToCart([
            {
              variantId: variantId || blok?.variantId,
              quantity: 1,
              customAttributes: map(transformAttribute, keys(values)),
            },
          ]);
        }}
      >
        {({ isSubmitting, status, handleSubmit, isValid }) => (
          <Form id="consultation_form" onSubmit={handleSubmit}>
            <Box gap="medium">
              <Box
                direction="row-responsive"
                gap="medium"
                className="row"
                fill="horizontal"
              >
                <FieldsContainer flex="grow" gap="medium">
                  <Field
                    label="Tell us a bit about your space and project"
                    name="_About the space"
                    component={FieldInputWithErrors}
                    inputComponent={InputTextArea}
                    placeholder="Tell us a bit about the space you're looking to paint…"
                  />

                  <Field
                    label="Thinking about any specific colors or color families?"
                    name="_Color Preferences"
                    component={FieldInputWithErrors}
                    inputComponent={InputTextArea}
                    placeholder="Do you have any color preferences?"
                  />
                </FieldsContainer>
                <FieldsContainer flex="grow" gap="medium">
                  <Field
                    label="Anything else we should know?"
                    name="_Other Info"
                    component={FieldInputWithErrors}
                    inputComponent={InputTextArea}
                    placeholder="Anything you think might be helpful context. The more we know upfront the more helpful we can be."
                  />
                  <Field
                    label="Relevant Links"
                    name="_Links"
                    component={FieldInputWithErrors}
                    inputComponent={InputTextArea}
                    placeholder="If you have an instagram or pinterest board you'd like us to reference, enter the URL here."
                  />
                </FieldsContainer>
              </Box>

              <FieldsContainer margin={{ bottom: 'xsmall' }}>
                <Field name="_Uploads" component={InputFilesUpload} />
              </FieldsContainer>

              <Actions direction="row-responsive">
                <Box align="end" direction="column" fill="horizontal">
                  <Button
                    primary
                    type="submit"
                    disabled={
                      status === 'uploading' ||
                      status === 'pending' ||
                      isSubmitting ||
                      !isValid ||
                      loading
                    }
                    label="Submit Information"
                    icon={
                      <DynamicIcon type={loading ? 'loading' : 'rightArrow'} />
                    }
                    ref={submitRef}
                    reverse
                  />
                </Box>
              </Actions>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

ColorConsultation.propTypes = {
  variantId: PropTypes.string,
  blok: PropTypes.object,
};

export default ColorConsultation;
