import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'grommet';

const InputTextArea = (props) => {
  return <TextArea {...props.field} {...props} />;
};

export default InputTextArea;

InputTextArea.propTypes = {
  field: PropTypes.object,
};
