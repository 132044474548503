import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './uppy.css';
import { isEmpty, get, map } from '../../lib/nodash';

import AwsS3 from '@uppy/aws-s3';
import ms from 'ms';

import { Dashboard } from '@uppy/react';
import { Box } from 'grommet';

const locale = {
  strings: {
    dropPaste:
      'Upload pictures or videos of the space for our reference by dropping them here, or %{browse} for them',
  },
};

const uppy = new Uppy({
  id: 'uppy',
  autoProceed: true,
  allowMultipleUploads: true,
  debug: true,
  restrictions: {
    maxNumberOfFiles: 10,
    allowedFileTypes: [
      'image/*',
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.mp4',
      '.mov',
      '.heic',
    ],
  },
}).use(AwsS3, {
  limit: 2,
  timeout: ms('1 minute'),
  getUploadParameters: (file) => {
    // Send a request to signing endpoint.
    return fetch('https://backdrop-api.herokuapp.com/s3/sign', {
      method: 'post',
      // Send and receive JSON.
      headers: {
        accept: 'application/json',
        'X-Backdrop-Api-Key': process.env.GATSBY_BACKDROP_API_KEY,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        filename: file.name,
        contentType: file.type,
      }),
    })
      .then((response) => {
        // Parse the JSON response.
        return response.json();
      })
      .then((data) => {
        // Return an object in the correct shape.
        return {
          method: data.method,
          url: data.url,
          fields: {},
        };
      });
  },
});

const InputFilesUpload = (props) => {
  const {
    form: { setFieldValue, setStatus },
    field: { name },
  } = props;

  useEffect(() => {
    uppy
      .on('complete', (result) => {
        // SET FORM ENABLED
        setFieldValue(
          name,
          map((x) => get('uploadURL', x), result.successful)
        );
        setStatus('ready');
      })
      .on('error', () => {
        setStatus('error');
      })
      .on('upload', () => {
        // SET FORM DISABLED
        setStatus('uploading');
      })
      .on('file-added', () => {
        setStatus('pending');
      })
      .on('file-removed', () => {
        const { currentUploads } = uppy.getState();
        if (isEmpty(currentUploads)) {
          setStatus('ready');
        }
      });
  }, []);

  return (
    <Box>
      <div>
        <Dashboard
          uppy={uppy}
          width="100%"
          height="300px"
          locale={locale}
          proudlyDisplayPoweredByUppy={false}
        />
      </div>
    </Box>
  );
};

InputFilesUpload.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default InputFilesUpload;
